import dynamic from 'next/dynamic';

export type SimpleInfoModalProps = {
  isOpen?: boolean;
  title: string;
  text?: string;
  children?: React.ReactNode;
  modalType: 'primary' | 'secondary' | 'tertiary';
  onClose?: () => void;
};

export const SimpleInfoModal = dynamic<SimpleInfoModalProps>(
  () => import('components/modals/simpleInfoModal/SimpleInfoModal').then((mod) => mod.SimpleInfoModal),
  { ssr: false }
);
