import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import styles from './SkeletonArea.module.scss';

export type SkeletonAreaProps = {
  height?: string;
  className?: string;
  fullHeight?: boolean;
  internalClassName?: string;
  style?: CSSProperties;
  /** Value between 1 and 10 */
  delay?: number; // TOOD: ADD TO MAP -> AVAILABLE FROM 1 TO 10
  uid?: string;
};

export const SkeletonArea = ({
  height,
  className,
  internalClassName,
  fullHeight,
  delay,
  style,
  uid,
}: SkeletonAreaProps) => {
  const animationClass = useMemo(() => (delay ? styles[`delay-${delay}`] : ''), [delay]);
  return (
    <div
      style={{ ...style, height: !fullHeight ? `${height}rem` : '100%' }}
      data-uid={uid}
      data-kind="betting-option"
      className={classNames(styles.container, internalClassName ? styles[internalClassName] : '', className ?? '', {
        [animationClass]: !!delay,
      })}
    ></div>
  );
};
