import { Banner } from 'components/banner';
import { PokerTournamentResponseApi } from 'lib/api/pokerCatalogResponseApi';
import { GameQuickSlotResponseApi } from 'lib/api/quickSlotResponseApi';
// import { TemplateManifestazioneResponse } from 'lib/api/sport/sportTemplateManifestazioneResponse';
import { HomepageVisualComposerCms } from 'lib/datoCms/queries/getHomepage';
import { convertInBannerType, convertInImgType, updateHomepageVisualComposer } from 'lib/datoCms/utils';
import { Table } from 'types/casino-live';
import {
  GamesSkillGameDto,
  GamesStripe,
  SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsCacheTemplateManifestazioneDto,
} from 'types/swagger';
import { BingoGame } from '../../../lib/api/bingoCatalogResponseApi';
import { updateVisualComposer } from '../../../lib/datoCms/utils';
import { HomepageVisualComposer } from '../HomepageVisualComposer';
// TODO define return type for function

export interface PageCompositionProps<K> {
  visualComposer: Array<HomepageVisualComposerCms>;
  catalog: CatalogComposition<K>;
  slug: string;
}

export function createPageComposition<K>({
  visualComposer,
  catalog,
  slug,
}: PageCompositionProps<K>): Array<HomepageVisualComposer<any> | undefined> {
  return visualComposer.map((composition) => {
    const { banners, _modelApiKey } = composition;
    const list: {
      [key: string]: (composition?: HomepageVisualComposerCms) => HomepageVisualComposer<any> | undefined;
    } = {
      banner_on_page: () => {
        return banners ? updateHomepageVisualComposer(composition, convertInBannerType(banners[0])) : undefined;
      },
      benefit_block: () => {
        return composition.title ? updateHomepageVisualComposer(composition, composition) : undefined;
      },
      section_title_block: () => {
        return composition.title ? updateHomepageVisualComposer(composition, composition.title) : undefined;
      },
      scommesse_block: () => {
        return updateHomepageVisualComposer(composition, {
          title: composition.title,
          subtitle: composition.subtitle,
          isTopTemplate: (catalog?.template as unknown as SportsCacheTemplateDisciplinaDto)?.disciplina === 'sport',
        });
      },
      banner_unique_home: () => {
        return banners ? updateHomepageVisualComposer(composition, convertInBannerType(banners[0])) : undefined;
      },
      console_giochi_slot_list_homepage: () => {
        const gameSlots = catalog.games;
        if (gameSlots && gameSlots?.length > 0) {
          return updateVisualComposer(composition, gameSlots, slug);
        }
        return undefined;
      },
      stripe_scorrevole_cms_block: () => {
        return updateHomepageVisualComposer(composition, composition.stripeList);
      },
      le_nostre_app_block: () => {
        return updateHomepageVisualComposer(composition, composition);
      },
      bonus_list_block: () => {
        const bonusList = composition.bonusList.map((bonus) => {
          return {
            id: bonus.id,
            typeOfBonus: bonus.typeOfBonus,
            text: bonus.text,
            subtitle: bonus.subtitle,
            bgImage: convertInImgType(bonus.image),
            promoCode: bonus.promoCode,
            informationTitle: bonus.informationTitle,
            informationText: bonus.informationText,
          };
        });
        return updateHomepageVisualComposer(composition, {
          bonusList,
          title: composition.title,
          subtitle: composition.subtitle,
        });
      },
    };
    return list[_modelApiKey](composition);
  });
}

export function getGamesByType<T, K extends { games: Array<T & { id: string | number | null }> }>(
  catalog: K,
  key: string | undefined
): Array<T> | undefined {
  const { games, ...listOfSlots } = catalog;
  return games?.filter((game) => {
    if (key) {
      // CAST TO UNKNOWN IN ORDER TO OVERLAP TYPE CHECK
      // DOUBLE == INSTEAD === IN ORDER TO SATISFY NUMBER OR STRING CHECK
      return (catalog[key as keyof typeof listOfSlots] as unknown as Array<string>)?.some(
        (element) => element == game.id
      );
    }
    return null;
  });
}

export type CatalogComposition<K> = {
  games: Array<Table | BingoGame | GamesSkillGameDto | PokerTournamentResponseApi>; //Array<K & { id: string | number | null; slug: string }>;
  listQuickSlot?: GameQuickSlotResponseApi[];
  stripe?: GamesStripe;
  pageBannerList?: Array<Banner>;
  template?:
    | SportsCacheTemplateDisciplinaDto
    | SportsCacheTemplateAvvenimentoDto
    | SportsCacheTemplateManifestazioneDto
    | SportsCacheTemplateDisciplinaLiveDto;
};
