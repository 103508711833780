import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { preloadVisualComposerData, useVisualComposerSnaphot } from 'lib/ssr';

import { AmbienteContext } from 'context/Ambiente';
import { AppFragment } from 'enums/path-fragment';
import { BonusBar } from 'components/bonusBar';
import { DatoCMSResponse } from 'lib/types/dato-cms.response';
import { FooterWarning } from 'components/footerWarning';
import { ISR_REVALIDATE } from 'utility/constant';
import { Layout } from 'components/Layout';
import PageContent from 'components/page-content/homepage/PageContent';
import React from 'react';
import { Seo } from 'components/seo';
import { appInsight } from 'components/appInsight';
import { cleanConfig } from 'lib/datoCms/utils';
import { getDatoCMSData } from 'sites/snai/lib/datoCms/queries/getDatoCMSData';
import { getHomepage } from 'lib/datoCms/queries/getHomepage';
import { mergeTagsFromSeoData } from 'components/seo/utils';
import { ssrGeoComplyLicense } from 'lib/ssr/geo-comply';

const HomePage = ({
  site,
  header,
  footer,
  seoData,
  needHelp,
  bonusBar,
  cmsContent,
  disclaimer,
  ...oth
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { seoMetaTags, canonical, ...pageData } = cmsContent ?? {};
  useVisualComposerSnaphot(oth);

  return (
    <React.Fragment>
      {disclaimer && <FooterWarning warningLogo={footer.logoAdm18.url} {...disclaimer} />}
      {bonusBar && <BonusBar {...bonusBar} />}
      <Layout header={header} needHelp={needHelp} footer={footer}>
        <Seo siteSeo={site} metaTags={seoMetaTags} canonicalUrl={canonical} />
        <AmbienteContext.Provider value={{ pathScommessa: ['sport', 'scommessa'], ambiente: 'sport' }}>
          <PageContent homePage={pageData} seoData={seoData} />
        </AmbienteContext.Provider>
      </Layout>
    </React.Fragment>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  console.log(`## getStaticProps HOME`);

  try {
    const promises: Array<Promise<any>> = [];

    const prCmsData = getDatoCMSData('common', locale);
    promises.push(prCmsData);

    ssrGeoComplyLicense();

    const prHomepage = getHomepage(locale);
    promises.push(prHomepage);

    const [cmsData, pageData] = await Promise.all(promises);
    const { site, header, needHelp, footer, allLabels: labels, config } = cmsData as DatoCMSResponse;
    const { tabsWithAccordionModel: seoData } = pageData ?? {};

    const ssrData = await preloadVisualComposerData({
      slug: `${AppFragment.Sport}/homepage`,
      locale,
      visualComposer: pageData?.homePage?.visualComposer ?? [],
    });

    const cmsContent = mergeTagsFromSeoData({
      seoData,
      cmsData: pageData,
      fieldName: 'homePage',
    });

    return Promise.resolve({
      props: {
        site,
        header,
        footer,
        labels,
        config: cleanConfig(config),
        seoData: seoData ?? null,
        needHelp,
        cmsContent: cmsContent?.homePage,
        ...ssrData,
      },
      revalidate: ISR_REVALIDATE,
    });
  } catch (exception) {
    appInsight?.trackException({
      exception,
      id: 'home page',
    });

    return Promise.resolve({ notFound: true });
  }
};

export default HomePage;
