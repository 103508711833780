import { CatalogComposition, createPageComposition } from 'components/homepageVisualComposer/utils/utils';
import { useEffect, useMemo } from 'react';

import { GamesSkillGameDto } from 'types/swagger';
import { HomepageQuery } from 'lib/datoCms/queries/getHomepage';
import { HomepageVisualComposer } from 'components/homepageVisualComposer';
import { TabsWithAccordion } from 'components/tabsWithAccordion';
import { TabsWithAccordionType } from 'lib/datoCms/queries/getSeoData';
import { isClientSide } from 'utility/functions';
import { isSnaiSite } from 'utility/constant';
import { setCookie } from 'lib/persist/cookie';
import { useGetSlotByListNameQuery } from 'features/api/apiSlice';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;

type Props = {
  seoData?: TabsWithAccordionType;
  homePage: HomepageQuery['homePage'];
};

const PageContent = ({ homePage, seoData }: Props) => {
  const router = useRouter();

  const { visualComposer: homepageComposer } = homePage ?? {};

  // const sportTemplateResponseData = useTypedSelector((state: RootState) => state.sport.templateDisciplina);

  const { data } = useGetSlotByListNameQuery(
    { listName: 'listTop10', pageNumber: 1, pageSize: 10 },
    {
      skip: isSSR || !isSnaiSite,
      selectFromResult: ({ data }) => {
        const { games } = data ?? {};
        const result = { ...data, games: games ?? [] };
        return { data: result };
      },
    }
  );

  useEffect(() => {
    if (router.query['btag']) setCookie('btag', router.query['btag'] as string, 7 * 24 * 60 * 60);
  }, [router]);

  const newVisualComposer = useMemo(() => {
    if (!homepageComposer) return undefined;

    const catalog: CatalogComposition<Array<GamesSkillGameDto>> = Object.assign({}, data);

    return createPageComposition({
      slug: '',
      catalog,
      visualComposer: homepageComposer,
    }).map((e) => e!);
  }, [data, homepageComposer]);

  return (
    <div>
      {newVisualComposer && <HomepageVisualComposer components={newVisualComposer} />}
      <TabsWithAccordion tabs={seoData?.tabs ?? []} />
    </div>
  );
};

export default PageContent;
