import { ReactNode } from 'react';
import styles from './Wrapper.module.scss';

export type WrapperProps = {
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  bgColorTransparent?: boolean;
  borderRadius?: boolean;
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  classNameWrapper?: string;
};

export const Wrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
  const {
    noPaddingRight,
    noPaddingLeft,
    noPaddingTop,
    noPaddingBottom,
    bgColorTransparent,
    fullWidth,
    borderRadius,
    children,
    className,
    classNameWrapper,
  } = props;
  return (
    <section
      suppressHydrationWarning
      className={`
      ${noPaddingRight ? '' : styles.paddingRight}
      ${noPaddingLeft ? '' : styles.paddingLeft}
			${noPaddingTop ? '' : styles.paddingTop}
			${noPaddingBottom ? '' : styles.paddingBottom}
			${bgColorTransparent ? '' : styles.bgColor}
      ${borderRadius ? styles.borderRadius : ''}
      ${fullWidth ? styles.fullWidth : ''}
      ${className ? styles[className] : ''}
      ${classNameWrapper ? classNameWrapper : ''}
		`}
    >
      {children}
    </section>
  );
};
