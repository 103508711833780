import { RootState, useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { addToCartByKey, getBonusGold, removeFromCartByKey, updateTicket } from './actions';
import { selectBonusGold, selectSaldo } from 'features/dashboard/selectors';
import { selectIsActiveIppica, selectIsActiveVirtual } from 'features/configuration/selectors';
import {
  selectIsSistemaByTicket,
  selectPuntataSingolaMultiplaByTicketType,
  selectSliceTicketByType,
  selectTipo,
  selectTotalePuntataScommesseSistemaByTicket,
} from './carrelloSelectors';
import { useCallback, useEffect } from 'react';

import { BonusCarrelloDataDto } from 'lib/api/sport/cartBonusResponseApi';
import { MovimentiTipoScommessaEnum } from 'types/swagger';
import { Sistema } from './types';
import { bonusConfigurationAdded } from 'features/sport/components/sportTicket/sportTicketSlice';
import { bonusConfigurationAdded as ippicaBonusConfiguration } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { isSnaiSite } from 'utility/constant';
import { selectSistemaById as selectSistemaByIdIppica } from 'features/ippica/components/ippicaTicket/ippicaTicketSelectors';
import { selectSistemaById as selectSistemaByIdSport } from 'features/sport/components/sportTicket/sportTicketSelectors';
import { selectSistemaById as selectSistemaByVirtual } from 'features/virtual/components/virtualTicket/virtualTicketSelectors';
import { toggleCarrello } from './carrelloSlice';
import { useAmbienteContext } from 'context/Ambiente';
import { useInternalSession } from 'hooks/useInternalSession';
import { useLazyGetCartBonusQuery } from 'features/api/sportApiSlice';
import { useLazyGetSessionDataQuery } from 'features/api/userSlice';
import { useLazyGetVirtualBonusQuery } from 'features/api/virtualApiSlice';
import useWindowSize from 'hooks/useWindowSize';

export const useIsInCart = (keyToCheck?: string) => {
  const { ambiente } = useAmbienteContext();
  const esitiInCart = useTypedSelector((state) => {
    switch (ambiente) {
      case 'ippica': {
        return state.ippicaTicket.esiti;
      }
      case 'virtual-corse':
      case 'virtual-sport': {
        return state.virtualTicket.esiti;
      }
      case 'sport':
      default: {
        return state.sportTicket.esiti;
      }
    }
  });

  if (!keyToCheck) return false;

  return esitiInCart[keyToCheck] ? true : false;
};

export const useCartType = () => {
  const { ambiente } = useAmbienteContext();

  switch (ambiente) {
    case 'ippica':
      return MovimentiTipoScommessaEnum.Ippica;
    case 'virtual-corse':
    case 'virtual-sport':
      return MovimentiTipoScommessaEnum.Virtual;
    case 'sport':
    default:
      return MovimentiTipoScommessaEnum.Sport;
  }
};

export const useCartToggleHandler = (esitoKey?: string, cartBehavior: 'open' | 'close' | 'none' = 'open') => {
  const dispatch = useAppDispatch();
  const context = useAmbienteContext();

  const { isDesktop } = useWindowSize();
  const isActiveInCart = useIsInCart(esitoKey);
  const { isAuthenticated } = useInternalSession();

  const onCartToggleHandler = () => {
    if (!esitoKey) throw new Error('Esito is undefined!');

    if (isActiveInCart) {
      dispatch(removeFromCartByKey({ esitoKey, ...context }));
    } else {
      dispatch(addToCartByKey({ esitoKey, ...context }));
    }

    if (isAuthenticated) {
      dispatch(getBonusGold());
    }

    if (cartBehavior !== 'none') {
      dispatch(
        toggleCarrello({
          isOpen: cartBehavior === 'open',
          isFullCart: isDesktop,
        })
      );
    }
  };

  return onCartToggleHandler;
};

export const useHasMultipleCashes = () => {
  const isActiveVirtual = useTypedSelector(selectIsActiveVirtual);
  const isActiveIppica = useTypedSelector(selectIsActiveIppica);
  return isActiveVirtual || isActiveIppica;
};

export const useCarrelloBonus = () => {
  const tipo = useTypedSelector(selectTipo);
  const slice = useTypedSelector((state) => selectSliceTicketByType(state, tipo));

  const { session, status } = useInternalSession();
  const [getUserSession] = useLazyGetSessionDataQuery();
  const [getSportOfflineBonus] = useLazyGetCartBonusQuery();
  const [getVirtualBonus] = useLazyGetVirtualBonusQuery();

  const dispatch = useAppDispatch();

  const retrieveBonuses = useCallback(async () => {
    if (status === 'loading') return;

    if (status === 'authenticated') {
      const { bonusCarrelloKey } = session?.user!;
      const {
        BONUS: bonus,
        BONUS_S: bonusSistema,
        BONUS_PSIP2: bonusIppica,
        BONUS_M: bonusMultipla,
      } = (await getUserSession(bonusCarrelloKey!).unwrap()) as BonusCarrelloDataDto;
      dispatch(
        bonusConfigurationAdded({
          '1': bonus || bonusMultipla,
          '101': bonusSistema,
        })
      );
      dispatch(ippicaBonusConfiguration(bonusIppica));
      if (isSnaiSite) {
        dispatch(getBonusGold());
      }
    } else if (status === 'unauthenticated') {
      getSportOfflineBonus();
    }
    if (isSnaiSite) {
      getVirtualBonus();
    }
  }, [dispatch, getSportOfflineBonus, getUserSession, getVirtualBonus, session?.user, status]);

  useEffect(() => {
    retrieveBonuses();
    dispatch(updateTicket(slice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user]);
};

export const useIsSaldoGiocabile = () => {
  const tipo = useTypedSelector(selectTipo);

  const amount = useTypedSelector(selectSaldo) ?? 0;
  const bonusGoldFromState = useTypedSelector(selectBonusGold);

  const isSistema = useTypedSelector((state) => selectIsSistemaByTicket(state, tipo));
  const puntataSingolaMultipla = useTypedSelector((state) => selectPuntataSingolaMultiplaByTicketType(state, tipo));
  const totalePuntataScommesseSistema = useTypedSelector((state) =>
    selectTotalePuntataScommesseSistemaByTicket(state, tipo)
  );

  const puntata = isSistema ? totalePuntataScommesseSistema : puntataSingolaMultipla * 100;
  const bonus: number = bonusGoldFromState ? +bonusGoldFromState : 0;

  return amount !== undefined && puntata <= amount + (tipo === MovimentiTipoScommessaEnum.Sport ? bonus : 0);
};

export const useSistema = (id: string): Sistema | undefined => {
  const { ambiente } = useAmbienteContext();

  return useTypedSelector((state) => {
    let selector: (_state: RootState, _id: string) => Sistema | undefined;
    switch (ambiente) {
      case 'sport':
        selector = selectSistemaByIdSport;
        break;
      case 'virtual-corse':
      case 'virtual-sport':
        selector = selectSistemaByVirtual;
        break;
      case 'ippica':
        selector = selectSistemaByIdIppica;
    }

    return selector(state, id);
  });
};
