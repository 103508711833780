import { BannerLandscape } from 'components/banner/bannerLandscape';
import { BannerPromo } from 'components/bannerPromo';
import { Benefit } from 'components/benefit';
import { DefaultCarousel } from 'components/carousels/defaultCarousel';
import { Top10Carousel } from 'components/carousels/top10Carousel';
import { MyBonusCarousel } from 'components/myBonusCarousel';
import { ScommesseDaNonPerdereCarousel } from 'components/scommesseDaNonPerdereCarousel';
import { SectionTitle } from 'components/sectionTitle';
import { StripeScorrevoleLabels } from 'components/stripeScorrevoleLabels';
import {
  BANNER,
  BANNER_UNIQUE,
  BENEFIT,
  BONUS,
  LE_NOSTRE_APP,
  SCOMMESSE,
  SECTION_TITLE,
  SLOT_CLASSIFICA_CONSOLE_GIOCHI,
  SLOT_DEFAULT_CONSOLE_GIOCHI,
  STRIPE_FROM_CMS,
} from './constants';

export const RenderHomepageComponentByModel = (component: any) => {
  const modelName: string = component.modelApiKey;
  const componentsList: any = {
    // BANNER
    [BANNER_UNIQUE]: () => {
      return <BannerPromo {...component.componentData} />;
    },
    [BENEFIT]: () => {
      return <Benefit {...component.componentData} />;
    },
    [SECTION_TITLE]: () => {
      return <SectionTitle title={component.componentData} isAlignCenter />;
    },
    [SCOMMESSE]: () => {
      return (
        <ScommesseDaNonPerdereCarousel
          title={component.componentData.title ?? ''}
          subtitle={component.componentData.subtitle ?? ''}
          className="inEvidenzaContainer"
          isTopTemplate={component.componentData.isTopTemplate}
        />
      );
    },
    // // BANNER PROMO
    [BANNER]: () => {
      return <BannerLandscape data={component.componentData} />;
    },
    [SLOT_CLASSIFICA_CONSOLE_GIOCHI]: () => {
      return (
        <Top10Carousel
          title={component.title}
          subtitle={component.subTitle}
          typeOfItem="top10"
          listItem={component.componentData}
          url={component.link}
        />
      );
    },
    [SLOT_DEFAULT_CONSOLE_GIOCHI]: () => {
      return (
        <DefaultCarousel
          title={component.title}
          typeOfItem="slot"
          listItem={component.componentData}
          url={component.link}
          subtitle={component.subTitle}
        />
      );
    },
    [STRIPE_FROM_CMS]: () => {
      return <StripeScorrevoleLabels data={component.componentData} />;
    },
    [LE_NOSTRE_APP]: () => {
      const appList = component.componentData.appList.map((app) => {
        return {
          title: app.title,
          image: {
            src: app.logo.url,
          },
          heroImage: app.banner
            ? {
                src: app.banner.url,
              }
            : null,
          urlDonwloadAppIos: app.linkIos.length > 0 ? app.linkIos[0].href ?? null : null,
          urlDonwloadAppAndroid: app.linkAndroid.length > 0 ? app.linkAndroid[0].href ?? null : null,
          urlDonwloadAppAndroidLabel: app.versione,
          description: app.description,
        };
      });
      const href =
        component.componentData.urlAppPage.length > 0 && component.componentData.urlAppPage[0].href
          ? component.componentData.urlAppPage[0].href
          : null;
      return (
        <DefaultCarousel
          url={href?.indexOf('/') === 0 ? href.substring(1) : href}
          title={component.componentData.title}
          typeOfItem="app"
          listItem={appList}
        />
      );
    },
    [BONUS]: () => {
      return (
        <MyBonusCarousel
          listItem={component.componentData.bonusList}
          title={component.componentData.title}
          subtitle={component.componentData.subtitle}
        />
      );
    },
  };
  try {
    return componentsList[modelName]();
  } catch (error) {
    console.error('Error for modelName', modelName);
    console.error(error);
  }
};
