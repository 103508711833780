import { AnchorLink } from 'components/anchorLink';
import { DynamicImage } from 'components/Image';
import { FooterWarningProps } from '.';
import { isNotEmpty } from 'utility/functions';
import styles from './FooterWarning.module.scss';

export const FooterWarning = ({ warningLogo, warningText, link }: FooterWarningProps) => {
  return (
    <div className={link ? styles.containerWithLink : styles.container}>
      {link && (
        <AnchorLink className={styles.link} href={link.url}>
          {link.label}
        </AnchorLink>
      )}
      <div className={styles.warningTextContainer}>
        {isNotEmpty(warningLogo) && (
          <DynamicImage
            src={warningLogo}
            alt="Logo Snai"
            width={128}
            height={32}
            loading="lazy"
            decoding="async"
            className={styles.logo}
          />
        )}
        <div className={styles.warning} dangerouslySetInnerHTML={{ __html: warningText }} suppressHydrationWarning />
      </div>
    </div>
  );
};
