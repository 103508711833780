import { FreeMode, Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { memo, useRef } from 'react';
import { useDynamicTempate, useFilteredAvvenimentoByKey } from 'features/sport/hooks';

import { AmbienteContext } from 'context/Ambiente';
import { AnchorLink } from 'components/anchorLink';
import React from 'react';
import { ScommesseInEvidenzaCard } from 'components/cards/scommesseInEvidenceCard';
import { Wrapper } from 'components/wrapper';
import { breakpoint } from 'utility/constant';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { isTruthy } from 'utility/functions';
import styles from './ScommesseDaNonPerdereCarousel.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useHideNavigationCarousel } from 'hooks';
import { useRouter } from 'next/router';

export type ScommesseDaNonPerdereCarouselProps = {
  title: string;
  subtitle?: string;
  className: string;
  isTopTemplate: boolean;
};

const MyScommesseDaNonPerdereCarousel = memo(
  function ScommesseDaNonPerdereCarousel({
    title,
    subtitle,
    className,
    isTopTemplate,
  }: ScommesseDaNonPerdereCarouselProps) {
    const { slug, template: inEvidenzaKeys } = useDynamicTempate<Array<string>>();

    const swiperRef = useRef<any>(null);
    const labelTutteLeScommesse = useGetLabelByKey('label-all-scommesse-sportive');

    const keys = useFilteredAvvenimentoByKey(inEvidenzaKeys);
    const { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled', items: keys });

    if (!isTruthy(inEvidenzaKeys?.length)) {
      return <React.Fragment key="Scommesse-Da-Non-Perdere" />;
    }

    return (
      <Wrapper noPaddingLeft noPaddingRight noPaddingBottom>
        {title && <h2 className={classNames(styles.title, styles.paddingLeft)}>{title}</h2>}
        {subtitle && <p className={classNames(styles.subtitle, styles.paddingLeft)}>{subtitle}</p>}
        <div className={classNames(styles.container, styles[className])}>
          <Swiper
            key={`${slug}`}
            spaceBetween={10}
            grabCursor
            slidesPerView={'auto'}
            slidesOffsetBefore={20}
            slidesOffsetAfter={20}
            modules={[Navigation, FreeMode, Mousewheel]}
            updateOnWindowResize
            watchOverflow
            freeMode={{
              enabled: true,
              minimumVelocity: 0.2,
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={isScrollable}
            breakpoints={{
              [breakpoint.sm]: {
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 32,
              },
              [breakpoint.lg]: {
                grabCursor: false,
                spaceBetween: 24,
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 40,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onInit={(core) => (swiperSlideRef!.current = core.el)}
          >
            {keys?.map((key) => (
              <SwiperSlide key={`${slug}-${key}`}>
                <ScommesseInEvidenzaCard scommessaInEvidenzaKey={key} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.contentRight}>
          <AnchorLink href="/sport" className={styles.link}>
            {labelTutteLeScommesse}
          </AnchorLink>
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      prevProps.className === nextProps.className &&
      prevProps.isTopTemplate === nextProps.isTopTemplate
    );
  }
);

export const ScommesseDaNonPerdereCarousel = (props: ScommesseDaNonPerdereCarouselProps) => {
  const { isReady, isFallback, asPath } = useRouter();

  return (
    <AmbienteContext.Provider
      key={`content-next-events`}
      value={{
        ambiente: 'sport',
        pathScommessa: ['sport', 'inEvidenza', 'scommesse'],
      }}
    >
      <MyScommesseDaNonPerdereCarousel
        {...props}
        key={`MyScommesseDaNonPerdereCarousel-${isReady}-${isFallback}-${asPath}`}
      />
    </AmbienteContext.Provider>
  );
};
