import { MetaTag, OpenGraphMedia } from 'next-seo/lib/types';
import { capitalize, isClientSide, isTruthy } from 'utility/functions';

import { NextSeo } from 'next-seo';
import { useMemo } from 'react';

type GlobalSeo = {
  siteName?: string;
  titleSuffix?: string;
  twitterAccount?: string;
  facebookPageUrl?: string;
};

export type SeoProps = {
  siteSeo: SiteSeo;
  metaTags?: Array<SeoMetaTagType>;
  canonicalUrl?: string;
};

export type SiteSeo = {
  favicon: SeoMetaTagType | Array<SeoMetaTagType>;
  globalSeo?: GlobalSeo;
};

//  pageLink: PageLink;

export type SeoMetaAttribute = {
  name?: string;
  property?: string;
  content?: string;
  href?: string;
  rel?: string;
  sizes?: string;
  type?: string;
};

export type SeoMetaTagType = {
  /** the tag for the meta information */
  tag: string;
  /** the inner content of the meta tag */
  content: string | null;
  /** the HTML attributes to attach to the meta tag */
  attributes: SeoMetaAttribute | null;
};

type LinkTag = {
  rel: string;
  href: string;
  sizes?: string;
  type?: string;
  color?: string;
  keyOverride?: string;
};

/**
 * SEO component
 * @param siteSeo
 * @param metaTags
 * @param pageLink
 * @param canonicalUrl
 * @constructor
 */
export const Seo = ({ siteSeo, metaTags, canonicalUrl }: SeoProps) => {
  /**
   * Extract the title from the metatags
   */

  const { favicon, globalSeo } = siteSeo ?? {};

  const title: string = useMemo(() => {
    const hostname = (isClientSide() ? document?.location?.hostname : undefined) ?? '';
    const site = hostname.split('.').shift();
    const tag: SeoMetaTagType | undefined = metaTags?.find((t) => t.tag === 'title');
    return tag?.content ?? globalSeo?.siteName ?? capitalize(site) ?? '';
  }, [metaTags, globalSeo?.siteName]);

  /**
   * Extract the description from the metatags
   */
  const description: string | null | undefined = useMemo(() => {
    if (!metaTags) {
      return '';
    }

    const tag: SeoMetaTagType | undefined = metaTags.find(
      (t) => t.tag === 'meta' && t.attributes?.name === 'description'
    );
    return tag ? tag.attributes?.content : '';
  }, [metaTags]);

  /**
   * Build the canonical url
   */
  /* const canonical: string = useMemo(() => {
    let pagePath = link.as.indexOf('/') === 0 ? link.as.substring(1) : link.as;

    if (pagePath === '/') {
      pagePath = '';
    }

    if (!canonicalUrl || canonicalUrl === '') {
      return `${process.env.publicUrl}${pagePath}`;
    }

    if (canonicalUrl.indexOf('http') > -1) {
      return canonicalUrl;
    }

    return `${process.env.publicUrl}${
      process.env.publicUrl.endsWith('/') ? '' : '/'
    }${canonicalUrl}`;
  }, [canonicalUrl, link]); */

  /**
   * FB image
   */
  const ogImage: OpenGraphMedia | undefined = useMemo(() => {
    if (!metaTags) {
      return undefined;
    }

    const imageUrl = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:image');
    const imageWidth = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:image:width');
    const imageHeight = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:image:height');
    const imageAlt = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:image:alt');

    return {
      url: imageUrl?.attributes?.content,
      width: imageWidth ? parseInt(imageWidth.attributes?.content as string, 10) : undefined,
      height: imageHeight ? parseInt(imageHeight.attributes?.content as string, 10) : undefined,
      alt: imageAlt ? imageAlt.attributes?.content : undefined,
      type: 'image/jpeg',
    } as OpenGraphMedia;
  }, [metaTags]);

  const locale: string | undefined = useMemo(() => {
    if (!metaTags) {
      return '';
    }

    const meta = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:locale');

    return meta?.attributes?.content || undefined;
  }, [metaTags]);

  const siteName: string | undefined = useMemo(() => {
    if (!metaTags) {
      return '';
    }

    const meta = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:site_name');

    return meta?.attributes?.content || undefined;
  }, [metaTags]);

  const ogType: string | undefined = useMemo(() => {
    if (!metaTags) {
      return undefined;
    }

    const meta = metaTags.find((t) => t.tag === 'meta' && t.attributes?.property === 'og:type');

    return meta?.attributes?.content || undefined;
  }, [metaTags]);

  const twitterCard: string | undefined = useMemo(() => {
    if (!metaTags) {
      return undefined;
    }

    const meta = metaTags.find((t) => t.tag === 'meta' && t.attributes?.name === 'twitter:card');

    return meta?.attributes?.content || undefined;
  }, [metaTags]);

  const additionalMeta: ReadonlyArray<MetaTag> = useMemo(() => {
    const excluded = ['title', 'description', 'og:locale', 'og:site_name', 'og:type', 'twitter:card'];
    if (!metaTags) {
      return [];
    }

    const additionalTags = metaTags
      .filter((m) => m.tag === 'meta' && !excluded.includes(m.attributes?.name ?? m.attributes?.property ?? ''))
      .map(
        (tag, idx) =>
          ({
            key: `additionalTags${idx}`,
            property: tag.attributes?.property,
            content: tag.attributes?.content,
            name: tag.attributes?.name,
          } as MetaTag)
      );

    return additionalTags;
  }, [metaTags]);

  const additionalLinks: ReadonlyArray<LinkTag> = useMemo(() => {
    if (!metaTags) {
      return [];
    }

    const links = metaTags.filter((m) => m.tag === 'link');

    if (!links) {
      return [];
    }

    return links.map((link, idx) => {
      return {
        key: `additionalLinks-${idx}`,
        href: link.attributes?.href,
        rel: link.attributes?.rel,
        sizes: link.attributes?.sizes,
        type: link.attributes?.type,
      } as LinkTag;
    });
  }, [metaTags]);

  const faviconLinks: ReadonlyArray<LinkTag> = useMemo(() => {
    if (!favicon) {
      return [];
    }

    const links = (Array.isArray(favicon) ? favicon : [favicon]).filter((m) => m.tag === 'link');

    if (!links) {
      return [];
    }

    return links.map((link, idx) => {
      return {
        key: `faviconLinks-${idx}`,
        href: link.attributes?.href,
        rel: link.attributes?.rel,
        sizes: link.attributes?.sizes,
        type: link.attributes?.type,
      } as LinkTag;
    });
  }, [favicon]);

  const twitter = globalSeo?.twitterAccount ? { site: globalSeo.twitterAccount, cardType: twitterCard } : undefined;

  const isDisabled = isTruthy(`${process.env.NEXT_PUBLIC_SEO_NO_INDEX}`);

  return isDisabled ? (
    <NextSeo
      title={title}
      noindex={true}
      nofollow={true}
      twitter={twitter ?? undefined}
      canonical={canonicalUrl ?? undefined}
      openGraph={{
        url: canonicalUrl,
        type: ogType,
        title: title ?? undefined,
        locale,
        images: ogImage ? [ogImage] : undefined,
        site_name: siteName,
        description: description ?? undefined,
      }}
      description={description ?? undefined}
      defaultTitle={siteName ?? undefined}
      additionalMetaTags={additionalMeta}
      additionalLinkTags={[...additionalLinks, ...faviconLinks]}
    />
  ) : (
    <NextSeo
      title={title}
      twitter={twitter ?? undefined}
      canonical={canonicalUrl ?? undefined}
      openGraph={{
        url: canonicalUrl,
        type: ogType,
        title: title ?? undefined,
        locale,
        images: ogImage ? [ogImage] : undefined,
        site_name: siteName,
        description: description ?? undefined,
      }}
      description={description ?? undefined}
      defaultTitle={siteName ?? undefined}
      additionalMetaTags={additionalMeta}
      additionalLinkTags={[...additionalLinks, ...faviconLinks]}
    />
  );
};
